import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
               <span className="purple"> About </span> Me
            </h1>
            <p className="home-about-body">
              I have around 3 years of experience building <b className="purple"> highly scalable and reliable applications. </b>
              <br />
              <br />I have worked with technologies like
              <i>
                <b className="purple"> Java, Python, React, Angular, Kubernetes, Docker and AWS. </b>
              </i>
              <br />
              <br />
              I'm currently working as a Platform Engineer at 
              <i>
                <b className="purple"> Disney+ Hotstar. </b>
              </i>
              <br />
              <br />
              {/* Whenever possible, I also apply my passion for developing products
              with <b className="purple">Node.js</b> and
              <i>
                <b className="purple">
                  {" "}
                  Modern Javascript Library and Frameworks
                </b>
              </i>
              &nbsp; like
              <i>
                <b className="purple"> React.js and Next.js</b>
              </i> */}
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={"https://ca.slack-edge.com/E4BGDDU1H-U02A4MAK9DM-ad929764c168-512"} 
              alt="avatar" 
              style={{borderRadius:"50%",width:"200px", height:"200px", marginTop:"100px" }}
              />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>My socials</h1>
            <p>
              {/* Feel free to <span className="purple">connect </span>with me */}
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/sandyjswl"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                {/* <a
                  href="https://twitter.com/sandyjswl"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a> */}
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/sandyjswl/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/sandyjswl"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;

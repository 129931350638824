import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            {/* Hi Everyone, I am <span className="purple">Soumyajit Behera </span>
            from <span className="purple"> Bhubaneswar, India.</span>
            <br />I am a junior pursuing IMSC in Maths and Computing in BIT
            Mesra.
            <br />
            <br />
            
 */}

            Hi! I’m <span className="purple">Sandeep</span>. I have close to 3 years of experience building scalable distributed systems and full stack applications.
             In these past three years i’ve played various roles such as Backend Developer, Fullstack Developer and Platform Engineer. 
             Currently i’m working at Disney+ Hotstar, where i’m working with the Infrastructure Team. 
             I have experience working with Python, Java, Angular, React and Spring Boot.
              On the infrastructure side i have worked with Kubernetes, Docker, monitoring tools like Victoria Metrics and API Gateway such as Ambassador.
             I also have experience setting up CI/CD pipelines using GoCD and Gitlab.
          </p>
          {/* <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Writting Tech Blogs
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul> */}

          {/* <p style={{ marginBlockEnd: 0, color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">Soumyajit</footer> */}
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;

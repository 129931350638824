import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
} from "react-icons/di";
import { SiPytorch, SiTensorflow, SiFirebase } from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <p style={{fontSize:"23px"}}>

        <b className="purple">Backend </b> -Java, Python, Go, Bash
        <br />
        <b className="purple">Frontend - </b> React, Angular, Material UI
        <br />
        <b className="purple">Databases - </b> PostgreSQL, SQL Server, MongoDB, Cassandra
        <br />
        <b className="purple">Infrastructure - </b> Docker, Kubernetes, Helm, GoCD, Terraform, Helm
        <br />
        <b className="purple">Monitoring - </b> Prometheus, Victoria Metrics, Grafana
        <br />
        <b className="purple">Cloud Platforms - </b> Amazon Web Services, Google Cloud Platform
        <br />
        <b className="purple">Other - </b> Git, Linux, Maven, Event Driven Architecture
      </p>
    </Row>
  );
}

export default Techstack;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import socialhub from "../../Assets/Projects/social-hub-demo.png";
import shopping from "../../Assets/Projects/shopping.png";
import cricket from "../../Assets/Projects/cricket.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Some  <strong className="purple">Personal Projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={socialhub}
              isBlog={false}
              title="SocialHub"
              description="Socialhub is a web application where a user can share multiple social media links. A socialhub profile link can be used to share all your prominent social media links in a single page."
              techStack="React, Material UI, Python, FastApi"
              link="https://socialhub.sandyjswl.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={shopping}
              isBlog={false}
              title="Shopping Price Alert"
              description="Shopping Price Alert is an application where you can enter your email and the link to a flipkart/amazon product. And when there is a price drop in the product, you will be notified by email and phone number."
              techStack="Angular, Python, BeautifulSoup"
              link="https://youtu.be/t3ItJJrlcZ0"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={cricket}
              isBlog={false}
              title="Cricket statsguru"
              description="Cricket Statsguru is an application where you can view different types of stats of cricket players from all around the world in different formats. Here you can also compare the stats of two different players."
              techStack="Angular, Python, FastApi, Pandas, Matplotlib"
              link="https://youtu.be/F7rNIDG1V5M"
            />
          </Col>
{/* 
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Bits-0f-C0de"
              description="My personal blog page build with Next.js and Tailwind Css which takes the content from makdown files and renders it using Next.js. Supports dark mode and easy to write blogs using markdown."
              link="https://github.com/soumyajit4419/Bits-0f-C0de"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Ai For Social Good"
              description="Using 'Natural Launguage Processing' for the detection of suicide-related posts and user's suicide ideation in cyberspace  and thus helping in sucide prevention."
              link="https://github.com/soumyajit4419/AI_For_Social_Good"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
              link="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
            />
          </Col> */}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
